<template>
  <div class="store">
    <van-list v-model="loading" :finished="finished" :finished-text="$t.noMoreData" @load="onLoad">
      <store-top :shopData="shopData" @serachShop="serachShop"></store-top>
      <goods-list :hotProducts="hotProducts" :allProducts="allProducts"></goods-list>
    </van-list>
  </div>
</template>
<script>
import StoreTop from "./components/StoreTop";
import GoodsList from "./components/GoodsList";

import Vue from "vue";
import { List } from "vant";
Vue.use(List);
export default {
  name: "Store",
  components: {
    StoreTop,
    GoodsList
  },
  data() {
    return {
      sreachValues: "",
      shopData: {},
      hotProducts: [],
      allProducts: [],
      pageIndex: 1,
      isNoData: false,
      isfirst: true,
      loading: false,
      finished: false,
      shopId: ""
    };
  },
  beforeMount() {
    const { ShopId } = this.$route.query;
    this.shopId = ShopId;
  },
  mounted() {
    this.loadShop();
  },
  methods: {
    // 首次加载数据
    loadShop() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      let param = {};
      param = {
        SreachValues: this.sreachValues,
        PageIndex: this.pageIndex,
        ShopId: this.shopId
      };
      this.$api.cshop
        .loadShop(param)
        .then(res => {
          console.log(res);
          const data = res.data;
          this.shopData = data;
          console.log(this.shopData);
          this.hotProducts = data.HotProducts;
          this.allProducts = data.AllProducts;
          this.isfirst = false;
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
        })
        .catch(error => {
          this.isfirst = false;
          console.log(error);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message
          });
        });
    },
    // 加载更多
    onLoad() {
      // 加载更多
      console.log("执行加载更多");
      if (this.isNoData) {
        this.loading = false;
        this.finished = true;
        return;
      }
      this.loading = false;
      this.finished = false;
      if (this.isfirst) return;
      console.log("通过");
      this.loading = true;
      this.finished = false;
      let param = {};
      param = {
        SreachValues: this.sreachValues,
        PageIndex: this.pageIndex + 1,
        ShopId: this.shopId
      };
      console.log(param);
      this.$api.cshop
        .loadShop(param)
        .then(res => {
          console.log(res);
          const data = res.data;
          if (data.AllProducts === null) {
            this.loading = false;
            this.finished = true;
            this.isNoData = true;
          } else {
            this.loading = false;
            this.allProducts = this.allProducts.concat(data.AllProducts);
            this.pageIndex = this.pageIndex + 1;
          }
        })
        .catch(error => {
          console.log(error);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message
          });
        });
    },
    // 搜索方法
    serachShop(sreachValues) {
      this.sreachValues = sreachValues;
      this.pageIndex = 1;
      this.isfirst = true;
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      let param = {};
      param = {
        SreachValues: this.sreachValues,
        PageIndex: this.pageIndex,
        ShopId: this.shopId
      };
      this.$api.cshop
        .loadShop(param)
        .then(res => {
          console.log(res);
          const data = res.data;
          this.allProducts = data.AllProducts;
          this.isfirst = false;
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
        })
        .catch(error => {
          this.isfirst = false;
          console.log(error);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message
          });
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.store {
  width: 100%;
}
</style>

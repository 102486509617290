<template>
  <div class="storeTop">
    <div class="box">
      <div class="info">
        <figure class="userImg">
          <img
            class="userImgIcon"
            :src="
              shopData.ShopHeadPhoto && imgUrlFilter(shopData.ShopHeadPhoto)
            "
          />
        </figure>
        <section class="infoRight">
          <p class="infoRightUserName">{{ shopData.ShopName }}</p>
          <p class="infoRightUserP">{{ shopData.ShopDescription }}</p>
        </section>
      </div>
    </div>
    <div class="bottom"></div>
    <!-- 搜索 -->
    <div class="searchBox">
      <div class="searchInfo">
        <input class="searchInoput" v-model="search" :placeholder="$t.cStore.pleaseEnterContent" />
        <figure class="searchLogo" @click="serachShop">
          <img class="searchLogoIcon" src="~assets/img/client/icon_home_search@2x.png" />
        </figure>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "StoreTop",
  props: ["shopData"],
  data() {
    return {
      search: ""
    };
  },
  methods: {
    imgUrlFilter(value) {
      return this.$api.formatImageUrl(value);
    },
    serachShop() {
      this.$emit("serachShop", this.search);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.storeTop {
  width: 100%;
  // background-color: red;
  position: relative;
  .bottom {
    background-color: #f6f6f6;
    height: 50px;
    width: 100%;
    // background-color: aqua;
  }
  .box {
    width: 100%;
    background-image: url("~assets/img/client/bg_1@2x.png");
    // background-repeat: no-repeat;
    background-size: cover;
    height: 450px;
    .info {
      width: 90%;
      margin: 0 auto;
      padding: 250px 0 10px 0;
      @include publicFlex;
      .userImg {
        margin: 0;
        padding: 0;
        width: 23%;
        .userImgIcon {
          width: 112px;
          height: 112px;
          border-radius: 100%;
          object-fit: cover;
          -webkit-object-fit: cover;
        }
      }
      .infoRight {
        width: 77%;
        .infoRightUserName {
          margin: 0;
          padding: 0;
          font-size: 34px;
          color: #ffffff;
          width: 100%;
          font-weight: bold;
        }
        .infoRightUserP {
          margin: 0;
          padding: 0;
          margin-top: 16px;
          font-size: 28px;
          color: #ffffff;
          width: 100%;
        }
      }
    }
  }
  .searchBox {
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    .searchInfo {
      margin: 0 auto;
      width: 580px;
      padding: 25px 40px;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      border-radius: 50px;
      background-color: #ffffff;
      position: relative;
      .searchInoput {
        width: 100%;
        border: none;
        background-color: #ffffff;
        font-size: 28px;
        color: #a7a7a7;
      }
      .searchLogo {
        @include publicFlex;
        margin: 0;
        padding: 0;
        position: absolute;
        justify-content: center;
        -webkit-justify-content: center;
        right: 30px;
        top: 23px;
        .searchLogoIcon {
          width: 45px;
          height: 45px;
          object-fit: cover;
          -webkit-object-fit: cover;
          // background-color: #434343;
        }
      }
    }
  }
}
</style>
